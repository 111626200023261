import React from "react";
import "./PricingSection.css";

const PricingSection = () => {
    return (
        <section id="pricing" className="pricing-section">
            <h2>Our Pricing</h2>
            <p className="pricing-intro">
                Choose the package that best fits your needs. Projects exceeding the page limits listed for each tier
                will incur additional charges or move to custom pricing based on the project's scope.
            </p>

            <div className="pricing-cards">
                {/* Basic Package */}
                <div className="pricing-card">
                    <div className="card-header basic">
                        <h3>Basic</h3>
                    </div>
                    <div className="card-body">
                        <p className="price">$0.10 <span>/ per page</span></p>
                        <p>Minimum Cost: <strong>$500</strong></p>
                        <p>Page Limit: <strong>Up to 10,000 pages</strong></p>
                        <ul className="features">
                            <li>Basic scanning and OCR</li>
                            <li>No advanced organization</li>
                            <li>Onsite scanning available</li>
                            <li>Access to dashboard for file management</li>
                            <li>Integration with client preferred cloud software</li>
                        </ul>
                        <p className="additional-charges">Additional pages: $0.12 per page</p>
                    </div>
                </div>

                {/* Mid-Tier Package */}
                <div className="pricing-card">
                    <div className="card-header mid-tier">
                        <h3>Mid-Tier</h3>
                    </div>
                    <div className="card-body">
                        <p className="price">$0.15 <span>/ per page</span></p>
                        <p>Minimum Cost: <strong>$750</strong></p>
                        <p>Page Limit: <strong>Up to 15,000 pages</strong></p>
                        <ul className="features">
                            <li>Searchable PDFs</li>
                            <li>Basic metadata tagging</li>
                            <li>Folder organization</li>
                            <li>Onsite scanning available</li>
                            <li>Access to dashboard for file management</li>
                            <li>Integration with client preferred cloud software</li>
                        </ul>
                        <p className="additional-charges">Additional pages: $0.18 per page</p>
                    </div>
                </div>

                {/* Premium Package */}
                <div className="pricing-card">
                    <div className="card-header premium">
                        <h3>Premium</h3>
                    </div>
                    <div className="card-body">
                        <p className="price">$0.25 <span>/ per page</span></p>
                        <p>Minimum Cost: <strong>$1,250</strong></p>
                        <p>Page Limit: <strong>Up to 20,000 pages</strong></p>
                        <ul className="features">
                            <li>Advanced tagging</li>
                            <li>System integration</li>
                            <li>Compliance support</li>
                            <li>Onsite scanning available</li>
                            <li>Access to dashboard for file management</li>
                            <li>Integration with client preferred cloud software</li>
                        </ul>
                        <p className="additional-charges">Additional pages: $0.30 per page</p>
                    </div>
                </div>
            </div>

            <div className="custom-pricing">
                <p>
                    Have a large project?{" "}
                    <a
                        href="mailto:contact@paperlessiq.com?subject=Custom%20Pricing%20Inquiry&body=Hi%20PaperlessIQ%20Team,%0A%0AI%20would%20like%20to%20discuss%20custom%20pricing%20options.%20Please%20let%20me%20know%20how%20to%20proceed.%0A%0AThank%20you!"
                        className="contact-link"
                    >
                        Contact us
                    </a>{" "}
                    for custom pricing and volume discounts.
                </p>
            </div>

        </section>
    );
};

export default PricingSection;
