import React from "react";
import Navbar from "../components/Navbar";
import "../pages/AboutPage.css"; // Ensure this path matches your setup
import founderImage from "../assets/2.png"; // Correct dynamic image import

const AboutPage = () => {
    return (
        <div className="about-page-container">
            <Navbar/>
            {/* Hero Section */}
            <section className="about-hero">
                <h1>About PaperlessIQ</h1>
                <p>
                    Welcome to PaperlessIQ! We are on a mission to simplify how small businesses
                    and nonprofits manage their paperwork. By transforming physical documents into
                    accessible, AI-ready digital assets, we aim to save time, space, and resources
                    for organizations like yours.
                </p>
            </section>

            {/* Meet the Founder Section */}
            <section className="about-founder-section">
                <h2>Meet the Founder</h2>
                <div className="founder-profile">
                    <div className="founder-photo-container">
                        <img
                            src={founderImage}
                            alt="Laurin - Founder"
                            className="founder-photo"
                        />
                    </div>
                    <div className="founder-details">
                        <p>
                            Hi, I’m Laurin, the creator of PaperlessIQ. As a computer science
                            graduate who studied at Rice University with a passion for digital
                            transformation, I built PaperlessIQ to empower businesses and nonprofits
                            to streamline their operations and unlock the potential of their paperwork.
                            My background in AI and software development drives me to create tools
                            that are both user-friendly and impactful.
                        </p>
                    </div>
                </div>
            </section>

            {/* Mission Section */}
            <section className="mission-section">
                <h2>Our Mission</h2>
                <p>
                    At PaperlessIQ, we believe that going digital should be simple and accessible
                    for everyone. Whether you're looking to organize years of paperwork or make your
                    documents AI-ready, we’re here to help you every step of the way.
                </p>
            </section>

            {/* CTA Section */}
            <section className="cta-section">
                <h2>Ready to Get Started?</h2>
                <p>
                    Let’s transform the way you manage your paperwork. Join us on our mission
                    to digitize the future.
                </p>
                <a
                    href="mailto:contact@paperlessiq.com?subject=Inquiry%20from%20Website&body=Hi%20PaperlessIQ%20Team,"
                    className="cta-button"
                >
                    Contact Us
                </a>
            </section>
        </div>
    );
};

export default AboutPage;
