import axios from "axios";

const API_URL = "http://localhost:5001/api/export"; // Base URL for export APIs

// Helper function to get authorization headers
const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("token")}`, // Retrieve the token from localStorage
});

// Fetch documents for selection and filtering
const fetchDocuments = async (filters = {}) => {
    try {
        const params = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                params.append(key, value);
            }
        });

        const response = await axios.get(`${API_URL}/documents?${params.toString()}`, {
            headers: getAuthHeaders(),
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching documents:", error.response?.data || error.message);
        throw error;
    }
};

// Export documents based on the selected format and document IDs
const exportDocuments = async (documentIds, format) => {
    try {
        const response = await axios.post(
            `${API_URL}/export`,
            { documentIds, format },
            { headers: getAuthHeaders() }
        );

        // Trigger download if export is successful
        const blob = new Blob([response.data], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `export.${format.toLowerCase()}`;
        link.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error exporting documents:", error.response?.data || error.message);
        throw error;
    }
};

export default {
    fetchDocuments,
    exportDocuments,
};
