import React from "react";
import "../styles/PageStyles.css";

const TermsOfService = () => {
    return (
        <div className="page-container">
            <h1>Terms of Service</h1>
            <p>Effective Date: January 31, 2024</p>
            <p>
                These Terms & Conditions govern your use of PaperlessIQ ("we," "our," or "us").
                By accessing or using our services, you agree to these terms.
            </p>

            <h2>1. Use of the Service</h2>
            <p>
                - You must be at least 18 years old or have parental/guardian consent to use PaperlessIQ. <br />
                - You are responsible for maintaining the confidentiality of your account credentials.
            </p>

            <h2>2. Document Processing</h2>
            <p>
                - You retain ownership of the documents uploaded to the platform. <br />
                - By uploading documents, you grant us permission to process and digitize them as per your instructions.
            </p>

            <h2>3. Prohibited Activities</h2>
            <ul>
                <li>Uploading illegal, harmful, or copyrighted material without permission.</li>
                <li>Using the service for fraudulent or malicious purposes.</li>
            </ul>

            <h2>4. Service Availability</h2>
            <p>
                We strive for 99.9% uptime but do not guarantee uninterrupted access. We reserve the right
                to suspend or terminate services for maintenance, updates, or breaches of terms.
            </p>

            <h2>5. Fees and Payments</h2>
            <p>
                - Fees for services will be displayed on our website or app. <br />
                - Payments are non-refundable unless explicitly stated.
            </p>

            <h2>6. Intellectual Property</h2>
            <p>
                - All intellectual property related to PaperlessIQ (logos, designs, features) is owned by us. <br />
                - You may not copy, distribute, or modify our intellectual property without permission.
            </p>

            <h2>7. Limitation of Liability</h2>
            <p>
                PaperlessIQ is not liable for: <br />
                - Loss of data due to user error or third-party service issues. <br />
                - Indirect or consequential damages arising from your use of our platform.
            </p>

            <h2>8. Termination</h2>
            <p>
                We may terminate or suspend your account if you breach these terms. Upon termination,
                all rights granted to you will cease immediately.
            </p>

            <h2>9. Governing Law</h2>
            <p>
                These terms are governed by the laws of Texas, USA.
            </p>

            <h2>10. Changes to These Terms</h2>
            <p>
                We may revise these Terms & Conditions from time to time. Updates will be communicated
                through the app or website.
            </p>

            <p>
                For inquiries, contact us at contact@paperlessiq.com.
            </p>
        </div>
    );
};

export default TermsOfService;
