import React, { useEffect, useState } from "react";
import documentService from "../services/documentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faFileAlt, faTags, faCloud } from "@fortawesome/free-solid-svg-icons";
import "./AnalyticsReportsPage.css";

const AnalyticsReportsPage = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [analytics, setAnalytics] = useState({
        totalDocuments: 0,
        mostFrequentTag: "",
        trends: [],
        wordCloud: [],
        statusBreakdown: [],
    });

    const calculateAnalytics = (data) => {
        const tagFrequency = {};
        const trends = [];
        const wordCloud = new Set();
        const statusBreakdown = {};

        data.forEach((doc) => {
            // Tag frequency
            doc.tags.forEach((tag) => {
                wordCloud.add(tag);
                tagFrequency[tag] = (tagFrequency[tag] || 0) + 1;
            });

            // Trends by date
            const uploadDate = new Date(doc.uploadDate).toLocaleDateString();
            const trend = trends.find((t) => t.date === uploadDate);
            if (trend) {
                trend.count += 1;
            } else {
                trends.push({ date: uploadDate, count: 1 });
            }

            // Status breakdown
            statusBreakdown[doc.status] = (statusBreakdown[doc.status] || 0) + 1;
        });

        // Safely calculate the most frequent tag
        const mostFrequentTag = Object.entries(tagFrequency).reduce(
            (a, b) => (b[1] > a[1] ? b : a),
            ["None", 0] // Default value
        )[0];

        return {
            totalDocuments: data.length,
            mostFrequentTag,
            trends,
            wordCloud: Array.from(wordCloud),
            statusBreakdown: Object.entries(statusBreakdown).map(([status, count]) => ({
                status,
                count,
            })),
        };
    };

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const data = await documentService.fetchDocuments(); // Call the correct function from documentService
                setDocuments(data);
                const computedAnalytics = calculateAnalytics(data);
                setAnalytics(computedAnalytics);
            } catch (err) {
                setError(err.message || "Failed to fetch analytics data.");
            } finally {
                setLoading(false);
            }
        };

        fetchDocuments();
    }, []);

    if (loading) {
        return <div className="loading-message">Loading analytics...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="analytics-reports-page">
            <h1>Analytics Reports</h1>

            {/* Summary Cards */}
            <div className="summaries">
                <div className="summary-card">
                    <FontAwesomeIcon icon={faFileAlt} className="summary-icon" />
                    <div className="summary-content">
                        <h3>Total Documents</h3>
                        <p>{analytics.totalDocuments}</p>
                    </div>
                </div>
                <div className="summary-card">
                    <FontAwesomeIcon icon={faTags} className="summary-icon" />
                    <div className="summary-content">
                        <h3>Most Frequent Tag</h3>
                        <p>{analytics.mostFrequentTag || "N/A"}</p>
                    </div>
                </div>
            </div>

            {/* Word Cloud */}
            <div className="chart-container">
                <h2>
                    <FontAwesomeIcon icon={faCloud} className="icon" /> Word Cloud
                </h2>
                <div className="chart-wrapper word-cloud">
                    {analytics.wordCloud.length > 0 ? (
                        analytics.wordCloud.map((word, index) => (
                            <span key={index} className="word">
                                {word}
                            </span>
                        ))
                    ) : (
                        <p>No word cloud data available.</p>
                    )}
                </div>
            </div>

            {/* Trends */}
            <div className="chart-container">
                <h2>
                    <FontAwesomeIcon icon={faChartLine} className="icon" /> Document Trends
                </h2>
                <div className="chart-wrapper trend-chart">
                    {analytics.trends.length > 0 ? (
                        <ul>
                            {analytics.trends.map((trend, index) => (
                                <li key={index}>
                                    {new Date(trend.date).toLocaleDateString()}: {trend.count} documents
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No trend data available.</p>
                    )}
                </div>
            </div>

            {/* Status Breakdown */}
            <div className="chart-container">
                <h2>
                    <FontAwesomeIcon icon={faFileAlt} className="icon" /> Document Status Breakdown
                </h2>
                <div className="chart-wrapper">
                    {analytics.statusBreakdown.length > 0 ? (
                        <ul className="status-list">
                            {analytics.statusBreakdown.map((status, index) => (
                                <li key={index} className="status-item">
                                    <span className="status-label">{status.status}:</span> {status.count} documents
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No status breakdown data available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AnalyticsReportsPage;
