import React from "react";

const NotificationsCenter = ({ notifications }) => {
    return (
        <div className="notifications-section">
            <h3 className="text-lg font-bold">Notifications</h3>
            <ul className="space-y-2">
                {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                        <li
                            key={index}
                            className="p-2 bg-gray-100 rounded-lg flex justify-between items-center"
                        >
                            <span>{notification.message}</span>
                            <button className="text-red-500 hover:underline">Dismiss</button>
                        </li>
                    ))
                ) : (
                    <p className="text-gray-500">No new notifications.</p>
                )}
            </ul>
        </div>
    );
};

export default NotificationsCenter;
