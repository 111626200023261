import React from "react";
import "./WhyGoDigital.css";

const WhyGoDigitalCard = () => {
    return (
        <div className="why-go-digital-card">
            <h2 className="digital-title">Why Go Digital?</h2>
            <p className="digital-description">
                Going digital isn’t just about saving paper—it’s about creating a more efficient, cost-effective, and productive business environment.
            </p>
            <ul className="digital-stats">
                <li>
                    <strong>Reduce Costs:</strong> Save up to <strong>40%</strong> on document-related expenses, from printing to physical storage.
                </li>
                <li>
                    <strong>Eliminate Paperwork Waste:</strong> Save <strong>$1 to $5</strong> per document by switching to digital workflows.
                </li>
                <li>
                    <strong>Free Up Space:</strong> Eliminate the need for physical storage, creating a cleaner and more organized office environment.
                </li>
                <li>
                    <strong>Boost Productivity:</strong> Increase efficiency by <strong>20%</strong> with streamlined digital processes and workflows.
                </li>
            </ul>
            <button className="cta-button">Learn More About Digital Transformation</button>
        </div>
    );
};

export default WhyGoDigitalCard;
