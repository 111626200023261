// src/services/userService.js
import axios from "axios"; // Make sure axios is installed

const API_URL = "http://localhost:5001/api/users"; // Updated URL to point to user routes

// Function to get user details by email
export const getUserDetails = async (email) => {
  try {
    const response = await axios.get(`${API_URL}/${email}`);
    return response.data; // Adjust based on your API response
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error; // Rethrow for handling in the component
  }
};

// Function to update user information
export const updateUserDetails = async (email, userData) => {
  try {
    const response = await axios.put(`${API_URL}/${email}`, userData);
    return response.data; // Adjust based on your API response
  } catch (error) {
    console.error("Error updating user details:", error);
    throw error; // Rethrow for handling in the component
  }
};

// Function to delete a user account
export const deleteUserAccount = async (email) => {
  try {
    const response = await axios.delete(`${API_URL}/${email}`);
    return response.data; // Adjust based on your API response
  } catch (error) {
    console.error("Error deleting user account:", error);
    throw error; // Rethrow for handling in the component
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axios.get("http://localhost:5001/api/users");
    return response.data; // Adjust based on your API response
  } catch (error) {
    console.error("Error fetching all users:", error);
    throw error; // Rethrow for handling in the component
  }
};


export default {
  getUserDetails,
  updateUserDetails,
  deleteUserAccount,
  getAllUsers
};
