import axios from 'axios';

const API_BASE_URL = 'http://localhost:5001/api'; // Replace with your backend base URL

// Update user details (title, profile picture)
export const updateUserDetails = async (userId, data) => {
    const response = await axios.put(`${API_BASE_URL}/user/${userId}`, data);
    return response.data;
};

// Update business details (name, logo, subscription)
export const updateBusinessDetails = async (businessId, data) => {
    const response = await axios.put(`${API_BASE_URL}/business/${businessId}`, data);
    return response.data;
};

// Upload profile picture
export const uploadProfilePicture = async (userId, file) => {
    const formData = new FormData();
    formData.append('profilePicture', file);

    const response = await axios.put(`${API_BASE_URL}/user/${userId}/profile-picture`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};

// Upload business logo
export const uploadBusinessLogo = async (businessId, file) => {
    const formData = new FormData();
    formData.append('logo', file);

    const response = await axios.put(`${API_BASE_URL}/business/${businessId}/logo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};



export default {
    updateUserDetails,
    updateBusinessDetails,
    uploadProfilePicture,
    uploadBusinessLogo,
};
