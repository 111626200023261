import React from "react";
import "./HowItWorksSection.css";

// Importing graphics
import cloudGraphic from "../../assets/hiw-cloud.png";
import dashboardGraphic from "../../assets/hiw-dashboard.png";
import archiveGraphic from "../../assets/hiw-archive.png";

const HowItWorksSection = () => {
    return (
        <section id="how-it-works" className="how-it-works-section">
            <div className="how-it-works-content">
                <h2 className="how-it-works-title">Our Simple 3-Step Process</h2>
                <p className="how-it-works-description">
                    Follow our seamless 3-step process to go from cluttered paperwork to organized, searchable, and secure digital files.
                </p>
                <div className="how-it-works-steps">
                    <div className="how-it-works-step">
                        <img
                            src={archiveGraphic}
                            alt="Upload Documents Graphic"
                            className="how-it-works-image"
                        />
                        <h3>Step 1: Submit Your Documents</h3>
                        <p>
                            Hand over your physical files or upload your digital documents through our secure platform. We also offer on-site pickups for businesses in Houston and nearby areas.
                        </p>
                    </div>
                    <div className="how-it-works-step">
                        <img
                            src={cloudGraphic}
                            alt="Digitize and Organize Graphic"
                            className="how-it-works-image"
                        />
                        <h3>Step 2: Digitize and Organize</h3>
                        <p>
                            Our advanced OCR (Optical Character Recognition) and AI tools process your documents, converting them into searchable digital files while organizing and tagging them for easy access.
                        </p>
                    </div>
                    <div className="how-it-works-step">
                        <img
                            src={dashboardGraphic}
                            alt="Access Anywhere Graphic"
                            className="how-it-works-image"
                        />
                        <h3>Step 3: Access Anytime, Anywhere</h3>
                        <p>
                            Access your files through a secure digital dashboard, enabling instant searches, sharing, and usage for daily operations or long-term storage.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorksSection;
