import React, { useState, useEffect, useContext } from 'react';
import userService from '../services/userService';
import { AuthContext } from '../context/AuthContext';
import './UserManagementPage.css';

const UserManagementPage = () => {
    const { user: loggedInUser } = useContext(AuthContext); // Get the logged-in user's data
    const [users, setUsers] = useState([]);
    const [isInvitingUser, setIsInvitingUser] = useState(false);
    const [inviteUser, setInviteUser] = useState({ name: '', email: '' });
    const [setupLink, setSetupLink] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 5;

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await userService.getAllUsers();
                // Filter users by businessId
                const filteredUsers = response.filter(
                    (user) => user.businessId === loggedInUser.businessId
                );
                setUsers(filteredUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
                setFeedbackMessage('Failed to load users. Please try again later.');
            }
        };

        fetchAllUsers();
    }, [loggedInUser.businessId]);

    const handleInviteClick = () => {
        setIsInvitingUser(true);
    };

    const handleCloseModal = () => {
        setIsInvitingUser(false);
        setInviteUser({ name: '', email: '' });
        setSetupLink('');
        setFeedbackMessage('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInviteUser((prev) => ({ ...prev, [name]: value }));
    };

    const handleInviteSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await userService.inviteUser(inviteUser);
            setSetupLink(response.setupLink);
            setFeedbackMessage('User invited successfully!');
        } catch (error) {
            console.error('Error inviting user:', error);
            setFeedbackMessage('Failed to invite user. Please try again.');
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredUsers = users.filter((user) => {
        const userName = user.name || '';
        const userEmail = user.email || '';
        return (
            userName.toLowerCase().includes(searchQuery) ||
            userEmail.toLowerCase().includes(searchQuery)
        );
    });

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    return (
        <div className="user-management-page">
            <h1>User Management</h1>
            <div className="controls">
                <input
                    type="text"
                    placeholder="Search by name or email..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <button onClick={handleInviteClick} className="add-user-button">
                    Invite User
                </button>
            </div>
            {feedbackMessage && <p className="feedback-message">{feedbackMessage}</p>}
            <table className="user-table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {currentUsers.map((user) => (
                    <tr key={user._id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role || 'User'}</td>
                        <td>{user.active ? 'Active' : 'Inactive'}</td>
                        <td>
                            <button>Edit</button>
                            <button>Deactivate</button>
                            <button>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserManagementPage;
