import React from "react";
import "./FeaturesSection.css";

const FeaturesSection = () => {
    return (
        <section id="features" className="features-section">
            <div className="features-content">
                <h2 className="features-title">Streamline Your Document Workflow</h2>
                <p className="section-description">
                    PaperlessIQ provides advanced tools designed to simplify and enhance how businesses manage their documents. From digitization to secure storage and seamless integration, we've got you covered.
                </p>
                <div className="feature-cards">
                    <div className="feature-card">
                        <h3>Onsite OCR & Scanning</h3>
                        <p>
                            Convert physical documents into digital formats with precision using Optical Character Recognition (OCR). Ideal for high-volume processing with minimal errors.
                        </p>
                    </div>
                    <div className="feature-card">
                        <h3>AI-Powered Tagging</h3>
                        <p>
                            Automatically tag and organize documents by keywords, categories, or metadata, making it effortless to locate files when you need them.
                        </p>
                    </div>
                    <div className="feature-card">
                        <h3>Data Security & Compliance</h3>
                        <p>
                            Protect sensitive information with robust encryption, secure access controls, and built-in compliance for industries like healthcare, finance, and law.
                        </p>
                    </div>
                    <div className="feature-card">
                        <h3>Workflow Integration</h3>
                        <p>
                            Easily connect digitized documents to your existing software, tools, or workflows, ensuring a seamless transition to a fully digital system.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;
