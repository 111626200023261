import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../services/contentfulClient";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Navbar from "../components/Navbar"; // Adjust path if Navbar is in a different folder
import "./BlogPost.css";

const BlogPost = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "blogPost",
                    "fields.slug": slug,
                    include: 1, // Ensure linked assets are included in the response
                });

                console.log("Response:", response); // Debugging

                if (response.items.length > 0) {
                    const post = response.items[0];

                    // Resolve featuredImage from includes.Asset
                    const assets = response.includes?.Asset || [];
                    const assetMap = assets.reduce((map, asset) => {
                        map[asset.sys.id] = asset.fields.file.url;
                        return map;
                    }, {});

                    const featuredImageId = post.fields.featuredImage?.sys?.id;
                    const imageUrl = featuredImageId ? `https:${assetMap[featuredImageId]}` : null;

                    setPost({
                        ...post.fields,
                        imageUrl, // Attach the resolved image URL
                    });
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching blog post:", error);
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    if (loading) {
        return (
            <>
                <Navbar />
                <p>Loading blog post...</p>
            </>
        );
    }

    if (!post) {
        return (
            <>
                <Navbar />
                <p>Blog post not found.</p>
            </>
        );
    }

    // Destructure fields with fallback for missing `imageUrl`
    const { title, date, content, imageUrl } = post;

    return (
        <>
            <Navbar />
            <article className="blog-post">
                <h1 className="blog-post-title">{title || "Untitled Blog Post"}</h1>
                <p className="blog-post-date">
                    Published on: {date ? new Date(date).toDateString() : "Unknown Date"}
                </p>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt={title || "Blog Post Image"}
                        className="blog-post-image"
                    />
                )}
                <div className="blog-post-content">
                    {content ? (
                        documentToReactComponents(content)
                    ) : (
                        <p>No content available for this blog post.</p>
                    )}
                </div>
            </article>
        </>
    );
};

export default BlogPost;
