import React, { useState } from "react";
import "./SavingsCalculator.css";

const SavingsCalculator = () => {
    const [pages, setPages] = useState(0);
    const [timeSpent, setTimeSpent] = useState(0); // Hours/month
    const [hourlyRate, setHourlyRate] = useState(0); // $/hour
    const [storageCost, setStorageCost] = useState(0); // $/month
    const [packageType, setPackageType] = useState("basic");

    const pricing = { basic: 0.10, midTier: 0.15, premium: 0.25 };
    const physicalCostPerPage = 0.50;
    const timeSavings = 0.20; // 20% efficiency improvement

    const calculateSavings = () => {
        const digitalCost = pages * pricing[packageType];
        const physicalCost = pages * physicalCostPerPage;
        const timeSaved = timeSpent * timeSavings;
        const timeSavingsCost = timeSaved * hourlyRate;

        return (
            physicalCost +
            storageCost * 12 + // Annual storage
            timeSavingsCost -
            digitalCost
        ).toFixed(2);
    };

    return (
        <div className="savings-calculator-card">
            <h2>Calculate Your Savings</h2>
            <p>Discover how much you can save with PaperlessIQ.</p>
            <form>
                <div className="form-group">
                    <label>Number of Pages:</label>
                    <input
                        type="number"
                        value={pages}
                        onChange={(e) => setPages(Number(e.target.value))}
                        placeholder="Enter number of pages"
                    />
                </div>
                <div className="form-group">
                    <label>Hours Spent on Documents (per month):</label>
                    <input
                        type="number"
                        value={timeSpent}
                        onChange={(e) => setTimeSpent(Number(e.target.value))}
                        placeholder="Enter hours"
                    />
                </div>
                <div className="form-group">
                    <label>Hourly Rate ($):</label>
                    <input
                        type="number"
                        value={hourlyRate}
                        onChange={(e) => setHourlyRate(Number(e.target.value))}
                        placeholder="Enter rate"
                    />
                </div>
                <div className="form-group">
                    <label>Monthly Storage Cost ($):</label>
                    <input
                        type="number"
                        value={storageCost}
                        onChange={(e) => setStorageCost(Number(e.target.value))}
                        placeholder="Enter storage cost"
                    />
                </div>
                <div className="form-group">
                    <label>Select Package:</label>
                    <select
                        value={packageType}
                        onChange={(e) => setPackageType(e.target.value)}
                    >
                        <option value="basic">Basic ($0.10/page)</option>
                        <option value="midTier">Mid-Tier ($0.15/page)</option>
                        <option value="premium">Premium ($0.25/page)</option>
                    </select>
                </div>
            </form>
            <div className="result">
                <h3>Estimated Annual Savings:</h3>
                <p>${calculateSavings()}</p>
            </div>
        </div>
    );
};

export default SavingsCalculator;
