import React from "react";
import SavingsCalculator from "../Home/SavingsCalculator";
import WhyGoDigitalCard from "../Home/WhyGoDigital";
import "./SideBySideCards.css";

const SideBySideCards = () => {
    return (
        <section className="side-by-side-cards">
            <div className="card-container">
                <SavingsCalculator/>
                <WhyGoDigitalCard/>
            </div>
        </section>

    );
};

export default SideBySideCards;
