import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import settingsService from '../services/settingsService';
import './SettingsPage.css';

const SettingsPage = () => {
    const { user, logout } = useContext(AuthContext); // Assuming user info is in context
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('user'); // Default to User tab
    const [businessName, setBusinessName] = useState(''); // For changing business name
    const [businessLogo, setBusinessLogo] = useState(null); // For uploading logo
    const [title, setTitle] = useState(user?.title || ''); // For user's title
    const [profilePicture, setProfilePicture] = useState(null); // For profile picture
    const [isSaving, setIsSaving] = useState(false); // State to handle save button state
    const [darkMode, setDarkMode] = useState(false); // Dark mode state

    useEffect(() => {
        // Load dark mode preference from localStorage
        const savedDarkMode = localStorage.getItem('darkMode') === 'enabled';
        setDarkMode(savedDarkMode);
        if (savedDarkMode) {
            document.body.classList.add('dark-mode');
        }
    }, []);

    const toggleDarkMode = () => {
        const newMode = !darkMode;
        setDarkMode(newMode);
        localStorage.setItem('darkMode', newMode ? 'enabled' : 'disabled');
        document.body.classList.toggle('dark-mode', newMode);
    };

    const handleSignOut = () => {
        logout();
        navigate('/');
    };

    const handleBusinessNameChange = (e) => setBusinessName(e.target.value);
    const handleLogoUpload = (e) => setBusinessLogo(e.target.files[0]);
    const handleProfilePictureUpload = (e) => {
        const file = e.target.files[0];
        setProfilePicture(URL.createObjectURL(file)); // Preview uploaded picture
    };
    const handleTitleChange = (e) => setTitle(e.target.value);

    const handleSaveUserChanges = async () => {
        setIsSaving(true);
        try {
            const updates = { title };
            if (profilePicture) {
                await settingsService.uploadProfilePicture(user.id, profilePicture);
            }
            await settingsService.updateUserDetails(user.id, updates);
            alert('User settings updated successfully!');
        } catch (error) {
            console.error('Error saving user changes:', error);
            alert('Failed to update user settings.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleSaveAdminChanges = async () => {
        setIsSaving(true);
        try {
            const updates = { name: businessName };
            if (businessLogo) {
                await settingsService.uploadBusinessLogo(user.businessId, businessLogo);
            }
            await settingsService.updateBusinessDetails(user.businessId, updates);
            alert('Business settings updated successfully!');
        } catch (error) {
            console.error('Error saving admin changes:', error);
            alert('Failed to update business settings.');
        } finally {
            setIsSaving(false);
        }
    };


    return (
        <div className="settings-page">
            <div className="tabs">
                <button
                    className={activeTab === 'user' ? 'active' : ''}
                    onClick={() => setActiveTab('user')}
                >
                    User Settings
                </button>
                {user?.role === 'admin' && (
                    <button
                        className={activeTab === 'admin' ? 'active' : ''}
                        onClick={() => setActiveTab('admin')}
                    >
                        Admin Settings
                    </button>
                )}
                <button onClick={toggleDarkMode} className="dark-mode-toggle">
                    {darkMode ? 'Disable Dark Mode' : 'Enable Dark Mode'}
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'user' && (
                    <div className="user-settings">
                        <h2>User Settings</h2>
                        <p><strong>Email:</strong> {user?.email}</p>
                        <p><strong>Role:</strong> {user?.role}</p>

                        {/* Profile Picture Upload */}
                        <div className="profile-picture-container">
                            <div
                                className="profile-picture"
                                style={{
                                    backgroundImage: profilePicture
                                        ? `url(${profilePicture})`
                                        : 'none',
                                }}
                            >
                                {!profilePicture && <p>Upload Picture</p>}
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleProfilePictureUpload}
                            />
                        </div>

                        <label>
                            Title:
                            <input
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                placeholder="Enter your title"
                            />
                        </label>
                        <button
                            onClick={handleSaveUserChanges}
                            disabled={isSaving}
                            className="save-button"
                        >
                            {isSaving ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                )}

                {activeTab === 'admin' && (
                    <div className="admin-settings">
                        <h2>Admin Settings</h2>
                        <label>
                            Business Name:
                            <input
                                type="text"
                                value={businessName}
                                onChange={handleBusinessNameChange}
                                placeholder="Enter new business name"
                            />
                        </label>
                        <label>
                            Business Logo:
                            <input type="file" onChange={handleLogoUpload} />
                        </label>
                        <button
                            onClick={handleSaveAdminChanges}
                            disabled={isSaving}
                            className="save-button"
                        >
                            {isSaving ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                )}
            </div>

            <div className="sign-out-section">
                <button className="sign-out-button" onClick={handleSignOut}>
                    Sign Out
                </button>
            </div>
        </div>
    );
};

export default SettingsPage;
