import axios from "axios";

const API_URL = "http://localhost:5001/api/compliance"; // Base URL for compliance-related APIs

// Helper function to get authorization headers
const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("token")}`, // Retrieve the token from localStorage
});

// Function to fetch compliance alerts with filters
const getComplianceAlerts = async (filters = {}) => {
    try {
        // Construct query parameters based on filters
        const params = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                params.append(key, value);
            }
        });

        const response = await axios.get(`${API_URL}/alerts?${params.toString()}`, {
            headers: getAuthHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching compliance alerts:", error.response?.data || error.message);
        throw error;
    }
};

// Function to get compliance alert filters (if needed from backend)
const getAlertFilters = async () => {
    try {
        const response = await axios.get(`${API_URL}/filters`, {
            headers: getAuthHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching alert filters:", error.response?.data || error.message);
        throw error;
    }
};

// Exporting the service functions
export default {
    getComplianceAlerts,
    getAlertFilters,
};
