import React, { useState, useEffect } from "react";
import exportService from "../services/exportService"; // API service for exporting data
import "./ExportPage.css";

const ExportPage = () => {
    const [filters, setFilters] = useState({
        startDate: "",
        endDate: "",
        fileType: "",
        tags: "",
    });
    const [documents, setDocuments] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [exportFormat, setExportFormat] = useState("CSV");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const docs = await exportService.fetchDocuments(filters);
                setDocuments(docs);
            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };
        fetchDocuments();
    }, [filters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleDocumentSelect = (docId) => {
        setSelectedDocuments((prev) =>
            prev.includes(docId) ? prev.filter((id) => id !== docId) : [...prev, docId]
        );
    };

    const handleExport = async () => {
        setLoading(true);
        setMessage("");
        try {
            await exportService.exportDocuments(selectedDocuments, exportFormat);
            setMessage("Export successful!");
        } catch (error) {
            console.error("Error during export:", error);
            setMessage("Export failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="export-page">
            <h1>Export Documents</h1>

            {/* Filters Section */}
            <div className="filters-section">
                <label>
                    Start Date:
                    <input type="date" name="startDate" onChange={handleFilterChange} />
                </label>
                <label>
                    End Date:
                    <input type="date" name="endDate" onChange={handleFilterChange} />
                </label>
                <label>
                    File Type:
                    <select name="fileType" onChange={handleFilterChange}>
                        <option value="">All</option>
                        <option value="application/pdf">PDF</option>
                        <option value="image/jpeg">JPEG</option>
                        <option value="image/png">PNG</option>
                    </select>
                </label>
                <label>
                    Tags:
                    <input type="text" name="tags" placeholder="Enter tags" onChange={handleFilterChange} />
                </label>
            </div>

            {/* Documents Table */}
            <div className="documents-table">
                <table>
                    <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                onChange={(e) => setSelectedDocuments(e.target.checked ? documents.map((doc) => doc._id) : [])}
                                checked={selectedDocuments.length === documents.length}
                            />
                        </th>
                        <th>Title</th>
                        <th>File Type</th>
                        <th>Uploaded</th>
                    </tr>
                    </thead>
                    <tbody>
                    {documents.map((doc) => (
                        <tr key={doc._id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedDocuments.includes(doc._id)}
                                    onChange={() => handleDocumentSelect(doc._id)}
                                />
                            </td>
                            <td>{doc.title}</td>
                            <td>{doc.fileType}</td>
                            <td>{new Date(doc.uploadDate).toLocaleDateString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Export Options */}
            <div className="export-options">
                <label>
                    Export Format:
                    <select value={exportFormat} onChange={(e) => setExportFormat(e.target.value)}>
                        <option value="CSV">CSV</option>
                        <option value="PDF">PDF</option>
                        <option value="JSON">JSON</option>
                    </select>
                </label>
                <button onClick={handleExport} disabled={loading || selectedDocuments.length === 0}>
                    {loading ? "Exporting..." : "Export"}
                </button>
            </div>

            {message && <p className="export-message">{message}</p>}
        </div>
    );
};

export default ExportPage;
