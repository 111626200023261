import React, { useState } from "react";
import "./IntegrationSettingsPage.css";

const IntegrationSettingsPage = () => {
    const [connectedCloudServices, setConnectedCloudServices] = useState([]);
    const [connectedScanners, setConnectedScanners] = useState([]);

    // Handle cloud integration connections
    const handleConnectCloudService = (service) => {
        // Mock integration logic
        setConnectedCloudServices((prev) => [...prev, service]);
        alert(`${service} connected successfully!`);
    };

    const handleDisconnectCloudService = (service) => {
        setConnectedCloudServices((prev) =>
            prev.filter((s) => s !== service)
        );
        alert(`${service} disconnected.`);
    };

    // Handle scanner integration
    const handleConnectScanner = (scanner) => {
        setConnectedScanners((prev) => [...prev, scanner]);
        alert(`${scanner} connected successfully!`);
    };

    const handleDisconnectScanner = (scanner) => {
        setConnectedScanners((prev) =>
            prev.filter((s) => s !== scanner)
        );
        alert(`${scanner} disconnected.`);
    };

    return (
        <div className="integration-settings">
            <h1>Integration Settings</h1>

            {/* Cloud Integrations Section */}
            <section className="cloud-integrations">
                <h2>Cloud Integrations</h2>
                <p>Connect your account to popular cloud platforms for seamless document management.</p>
                <div className="integration-options">
                    <button
                        onClick={() => handleConnectCloudService("Google Drive")}
                        disabled={connectedCloudServices.includes("Google Drive")}
                    >
                        {connectedCloudServices.includes("Google Drive")
                            ? "Connected to Google Drive"
                            : "Connect Google Drive"}
                    </button>
                    <button
                        onClick={() => handleConnectCloudService("Dropbox")}
                        disabled={connectedCloudServices.includes("Dropbox")}
                    >
                        {connectedCloudServices.includes("Dropbox")
                            ? "Connected to Dropbox"
                            : "Connect Dropbox"}
                    </button>
                </div>

                {connectedCloudServices.length > 0 && (
                    <div className="connected-services">
                        <h3>Connected Services:</h3>
                        <ul>
                            {connectedCloudServices.map((service, index) => (
                                <li key={index}>
                                    {service}{" "}
                                    <button onClick={() => handleDisconnectCloudService(service)}>
                                        Disconnect
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </section>

            {/* Scanner Integrations Section */}
            <section className="scanner-integrations">
                <h2>Scanner Integrations</h2>
                <p>Connect your scanner for direct document uploads and processing.</p>
                <div className="scanner-options">
                    <button
                        onClick={() => handleConnectScanner("Canon Scanner")}
                        disabled={connectedScanners.includes("Canon Scanner")}
                    >
                        {connectedScanners.includes("Canon Scanner")
                            ? "Connected to Canon Scanner"
                            : "Connect Canon Scanner"}
                    </button>
                    <button
                        onClick={() => handleConnectScanner("Epson Scanner")}
                        disabled={connectedScanners.includes("Epson Scanner")}
                    >
                        {connectedScanners.includes("Epson Scanner")
                            ? "Connected to Epson Scanner"
                            : "Connect Epson Scanner"}
                    </button>
                </div>

                {connectedScanners.length > 0 && (
                    <div className="connected-scanners">
                        <h3>Connected Scanners:</h3>
                        <ul>
                            {connectedScanners.map((scanner, index) => (
                                <li key={index}>
                                    {scanner}{" "}
                                    <button onClick={() => handleDisconnectScanner(scanner)}>
                                        Disconnect
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </section>
        </div>
    );
};

export default IntegrationSettingsPage;
