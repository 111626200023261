import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./DocumentDetailsPage.css";

const DocumentDetailsPage = () => {
    const { documentId } = useParams(); // Get documentId from URL
    const [document, setDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const token = localStorage.getItem("token"); // Get auth token
                const response = await axios.get(
                    `http://localhost:5001/api/documents/${documentId}/details`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setDocument(response.data);
            } catch (err) {
                console.error("Error fetching document:", err.response?.data || err.message);
                setError(err.response?.data?.message || "Failed to load document.");
            } finally {
                setLoading(false);
            }
        };

        fetchDocument();
    }, [documentId]);

    if (loading) {
        return <div className="loading-message">Loading document details...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="document-details-page">
            <h1>{document.title}</h1>
            <div className="document-info">
                <p>
                    <strong>File Type:</strong> {document.metadata?.fileType || "Unknown"}
                </p>
                <p>
                    <strong>File Size:</strong>
                    {(document.metadata?.fileSize / 1024).toFixed(2)} KB
                </p>
                <p>
                    <strong>Uploaded By:</strong> {document.metadata?.createdBy || "Unknown"}
                </p>
                <p>
                    <strong>Status:</strong> {document.status || "Unknown"}
                </p>
                <p>
                    <strong>Tags:</strong>
                    {document.tags?.length ? document.tags.join(", ") : "None"}
                </p>
                <p>
                    <strong>Upload Date:</strong>
                    {new Date(document.uploadDate).toLocaleString()}
                </p>
                <p>
                    <strong>Last Modified:</strong>
                    {new Date(document.lastModifiedDate).toLocaleString()}
                </p>
            </div>
        </div>
    );
};

export default DocumentDetailsPage;
