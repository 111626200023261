import React, { useState } from "react";
import "./TagManagementPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";


const TagManagementPage = () => {
    // Mock data for tags
    const mockTags = [
        { id: 1, name: "Invoice", documentCount: 25 },
        { id: 2, name: "Contract", documentCount: 15 },
        { id: 3, name: "Audit", documentCount: 5 },
        { id: 4, name: "Compliance", documentCount: 10 },
    ];

    const [tags, setTags] = useState(mockTags);
    const [searchQuery, setSearchQuery] = useState("");
    const [newTagName, setNewTagName] = useState("");
    const [editingTag, setEditingTag] = useState(null);

    // Filter tags based on search query
    const filteredTags = tags.filter((tag) =>
        tag.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Add a new tag
    const handleAddTag = () => {
        if (newTagName.trim() === "") return;
        setTags([...tags, { id: Date.now(), name: newTagName, documentCount: 0 }]);
        setNewTagName("");
    };

    // Delete a tag
    const handleDeleteTag = (id) => {
        setTags(tags.filter((tag) => tag.id !== id));
    };

    // Start editing a tag
    const handleEditTag = (tag) => {
        setEditingTag(tag);
    };

    // Save the edited tag
    const handleSaveTag = () => {
        setTags(
            tags.map((tag) =>
                tag.id === editingTag.id ? { ...tag, name: editingTag.name } : tag
            )
        );
        setEditingTag(null);
    };

    return (
        <div className="tag-management-page">
            <h1>Tag Management</h1>

            {/* Search and Add Tags */}
            <div className="tag-controls">
                <input
                    type="text"
                    placeholder="Search tags..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="add-tag">
                    <input
                        type="text"
                        placeholder="New tag name"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                    />
                    <button onClick={handleAddTag}>Add Tag</button>
                </div>
            </div>

            {/* Tags Table */}
            <div className="tags-table">
                <table>
                    <thead>
                    <tr>
                        <th>Tag Name</th>
                        <th>Document Count</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredTags.map((tag) => (
                        <tr key={tag.id}>
                            <td>
                                {editingTag && editingTag.id === tag.id ? (
                                    <input
                                        type="text"
                                        value={editingTag.name}
                                        onChange={(e) =>
                                            setEditingTag({...editingTag, name: e.target.value})
                                        }
                                    />
                                ) : (
                                    tag.name
                                )}
                            </td>
                            <td>{tag.documentCount}</td>
                            <td>
                                {editingTag && editingTag.id === tag.id ? (
                                    <button onClick={handleSaveTag}>Save</button>
                                ) : (
                                    <>
                                        <button className="edit" onClick={() => handleEditTag(tag)}>
                                            <FontAwesomeIcon icon={faEdit}/> Edit
                                        </button>
                                        <button className="delete" onClick={() => handleDeleteTag(tag.id)}>
                                            <FontAwesomeIcon icon={faTrash}/> Delete
                                        </button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

            {filteredTags.length === 0 && (
                <p className="no-tags">No tags found. Try adjusting your search.</p>
            )}
        </div>
    );
};

export default TagManagementPage;
