import React from "react";
import "../styles/PageStyles.css"; // Adjust the path as needed

const PrivacyPolicy = () => {
    return (
        <div className="page-container">
            <h1>Privacy Policy</h1>
            <p>
                At PaperlessIQ, we take your privacy seriously. This Privacy Policy outlines how
                we collect, use, and safeguard your data.
            </p>
            <h2>Effective Date: January 31, 2024</h2>
            <p>
                PaperlessIQ ("we," "our," or "us") is committed to protecting your privacy. This
                Privacy Policy explains how we collect, use, disclose, and safeguard your
                information when you use our services.
            </p>

            <h2>1. Information We Collect</h2>
            <ul>
                <li>
                    <strong>Personal Information:</strong> Name, email address, phone number, and
                    payment details for account creation and service transactions.
                </li>
                <li>
                    <strong>Uploaded Documents:</strong> Files uploaded to our platform for
                    digitization and processing.
                </li>
                <li>
                    <strong>Automatically Collected Information:</strong> IP address, browser type,
                    device information, and usage data through cookies and analytics tools.
                </li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <ul>
                <li>To provide and improve our services.</li>
                <li>To securely process, analyze, and digitize your documents.</li>
                <li>To communicate with you regarding updates, billing, and support.</li>
                <li>To comply with legal obligations or enforce our terms.</li>
            </ul>

            <h2>3. Document Privacy</h2>
            <p>
                Uploaded documents are used solely for digitization and are securely stored and
                processed. We do not share or sell your document content without your explicit
                consent.
            </p>

            <h2>4. Data Security</h2>
            <p>
                We use industry-standard encryption and security protocols to protect your data.
                However, no method of transmission over the internet or electronic storage is 100%
                secure.
            </p>

            <h2>5. Third-Party Services</h2>
            <p>
                We may use third-party services for payment processing or analytics. These third
                parties are bound to protect your data in accordance with their privacy policies.
            </p>

            <h2>6. Your Rights</h2>
            <ul>
                <li>Access, update, or delete your personal information.</li>
                <li>Request the deletion of uploaded documents after processing.</li>
                <li>Opt out of marketing communications.</li>
            </ul>

            <h2>7. Changes to This Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Changes will be notified
                through the app or website.
            </p>

            <h2>Contact Us</h2>
            <p>
                For questions or concerns about this Privacy Policy, contact us at{" "}
                <a href="mailto:contact@paperlessiq.com">contact@paperlessiq.com</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
