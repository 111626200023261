// src/index.js
import React from "react";
import ReactDOM from "react-dom/client"; // Import from react-dom/client
import App from "./App"; // Import the App component
import { AuthProvider } from "./context/AuthContext"; // Ensure this is imported if you're using context

const root = ReactDOM.createRoot(document.getElementById("root")); // Create root
root.render(
    <React.StrictMode>
      <AuthProvider> {/* Wrap your app with the AuthProvider */}
        <App /> {/* Render the App component which contains routing */}
      </AuthProvider>
    </React.StrictMode>
);
