import axios from "axios";

const API_URL = "http://localhost:5001/api/documents"; // Base URL for document-related APIs

// Helper function to get authorization headers
const getAuthHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`, // Retrieve the token from localStorage
});

// Function to fetch all documents for a specific business
const fetchDocuments = async () => {
  try {
    const response = await axios.get(API_URL, {
      headers: getAuthHeaders(),
    });
    console.log("Documents fetched successfully:", response.data); // Debugging
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error.response?.data || error.message);
    throw error;
  }
};


// Function to fetch all documents for a specific business (explicit function name)
const getDocuments = async () => {
  try {
    const response = await axios.get(`${API_URL}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error.response?.data || error.message);
    throw error;
  }
};

// Function to fetch recent documents
const getRecentDocuments = async () => {
  try {
    const response = await axios.get(`${API_URL}/recent`, { headers: getAuthHeaders() });
    return response.data;
  } catch (error) {
    console.error("Error in getRecentDocuments:", error.response?.data || error.message);
    throw error;
  }
};

// Function to upload a document
const uploadDocument = async (file, title) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);

    const response = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        ...getAuthHeaders(),
        "Content-Type": "multipart/form-data", // Ensure correct headers for file upload
      },
    });
    return response.data;
  } catch (error) {
    console.error("Upload error:", error.response?.data || error.message);
    throw error;
  }
};

// Function to delete a document
const deleteDocument = async (documentId) => {
  try {
    const response = await axios.delete(`${API_URL}/${documentId}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting document:", error.response?.data || error.message);
    throw error;
  }
};

// Function to fetch document details
const getDocumentDetails = async (documentId) => {
  try {
    const response = await axios.get(`${API_URL}/${documentId}/details`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching document details:", error.response?.data || error.message);
    throw error;
  }
};

export default {
  fetchDocuments,
  getDocuments, // Explicitly export `getDocuments`
  getRecentDocuments,
  uploadDocument,
  deleteDocument,
  getDocumentDetails,
};
