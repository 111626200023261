import React, { createContext, useState, useEffect } from "react";

// Create AuthContext with default values
const AuthContext = createContext({
  user: null,
  isAuthenticated: false,
  loading: true,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // User state
  const [loading, setLoading] = useState(true); // Loading state

  // Initialize authentication state on mount
  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage

      if (token) {
        console.log("Token found in localStorage:", token); // Debugging

        try {
          const response = await fetch("http://localhost:5001/api/auth/validate", {
            headers: {
              Authorization: `Bearer ${token}`, // Add Authorization header
            },
          });

          if (response.ok) {
            const userData = await response.json();
            setUser(userData); // Set user data if token is valid
            console.log("Token validated successfully. User data:", userData); // Debugging
          } else {
            console.error("Token validation failed with status:", response.status);
            logout(); // Clear invalid token
          }
        } catch (error) {
          console.error("Error validating token:", error);
          logout();
        }
      } else {
        console.log("No token found in localStorage");
        setUser(null);
      }

      setLoading(false); // Stop loading
    };

    initializeAuth();
  }, []); // Empty dependency array ensures this runs only once on mount

  // Login function
  const login = (userData) => {
    setUser(userData); // Update user state
    localStorage.setItem("user", JSON.stringify(userData)); // Store user data
    if (userData.token) {
      localStorage.setItem("token", userData.token); // Store token
      console.log("Token stored in localStorage:", userData.token); // Debugging
    } else {
      console.error("No token found in userData during login.");
    }
  };

  // Logout function
  const logout = () => {
    try {
      localStorage.removeItem("user");
      localStorage.removeItem("token"); // Clear token
      setUser(null); // Reset user state
      console.log("User logged out successfully."); // Debugging
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  // Derived authentication status
  const isAuthenticated = !!user;

  return (
      <AuthContext.Provider
          value={{
            user,
            isAuthenticated,
            loading,
            login,
            logout,
          }}
      >
        {!loading ? (
            children
        ) : (
            <div className="loading-spinner">
              {/* Replace with your preferred spinner component */}
              <p>Loading...</p>
            </div>
        )}
      </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
