import axios from "axios";

const API_BASE_URL = "http://localhost:5001/api/auth"; // Ensure this matches your backend

// Helper to get the stored token
const getAuthToken = () => localStorage.getItem("token");

// Axios Interceptor for Automatic Authorization
axios.interceptors.request.use(
    (config) => {
      const token = getAuthToken(); // Get token from localStorage
      if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Add Authorization header
      }
      return config;
    },
    (error) => Promise.reject(error) // Handle request errors
);

// Login function
const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, { email, password });
    console.log("Full login response:", response);

    if (response.data && response.data.token) {
      // Store token in localStorage
      localStorage.setItem("token", response.data.token);
      return response.data; // Return the full response object
    }

    throw new Error("Invalid response structure");
  } catch (error) {
    console.error("Login error:", error);
    throw error.response?.data?.message || error.message || "Login failed.";
  }
};

// Register function
const register = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/register`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("Registration response:", response);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Registration error:", error);
    throw error.response?.data?.message || error.message || "Registration failed.";
  }
};

// Logout function
const logout = () => {
  try {
    localStorage.removeItem("token"); // Clear token from localStorage
    console.log("User logged out successfully");
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

export default {
  login,
  register,
  logout,
  getAuthToken, // Export helper for external use if needed
};
