import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import authService from "../services/authService";
import "./LoginPage.css";
import logo from "../assets/paperlessiq-logo.png"; // Adjust path if needed

const LoginPage = () => {
    const { user, login } = useContext(AuthContext); // Use AuthContext
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false); // Add loading state
    const [rememberMe, setRememberMe] = useState(false); // Remember Me feature
    const navigate = useNavigate();

    // Redirect if already authenticated
    useEffect(() => {
        if (user) {
            navigate("/dashboard", { replace: true });
        }
    }, [user, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true); // Show loading indicator

        try {
            // Call login API
            const response = await authService.login(email, password);

            if (response && response.user) {
                login(response.user); // Update context with logged-in user

                // Remember Me functionality
                if (rememberMe) {
                    localStorage.setItem("rememberMe", JSON.stringify(response.user));
                } else {
                    localStorage.removeItem("rememberMe");
                }

                navigate("/dashboard", { replace: true }); // Redirect to dashboard
            } else {
                setError("Invalid email or password."); // Handle invalid response
            }
        } catch (error) {
            // Extract error message from response or fallback to default message
            const errorMessage =
                error.response?.data?.message ||
                error.message ||
                "Login failed. Please try again.";
            setError(errorMessage);
        } finally {
            setLoading(false); // Hide loading indicator
        }
    };


    return (
        <div className="login-container">
            <img src={logo} alt="PaperlessIQ Logo" className="login-logo" />
            <form onSubmit={handleLogin} className="login-form">
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-options">
                    <div className="remember-me">
                        <input
                            type="checkbox"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label htmlFor="rememberMe">Remember Me</label>
                    </div>
                    <div className="forgot-password">
                        <a href="/forgot-password">Forgot Password?</a>
                    </div>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                </button>
                {error && <div className="error-message">{error}</div>}
            </form>
            <div className="signup-link">
                Don't have an account? <a href="/register">Sign up</a>
            </div>
        </div>
    );
};

export default LoginPage;
