import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute = ({ redirectTo = "/login" }) => {
    const { isAuthenticated, loading } = useContext(AuthContext);

    if (loading) {
        // Show a loading spinner or message while authentication is being verified
        return <div className="loading-spinner">Loading...</div>;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
