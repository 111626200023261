import React, { useState } from "react";
import "./NotificationsPage.css";

const NotificationsPage = () => {
    // Mock data for notifications
    const mockNotifications = [
        { id: 1, message: "Your document 'Report Q3' was approved.", isRead: false, timestamp: "2024-11-20 10:30 AM" },
        { id: 2, message: "You have a new compliance alert.", isRead: false, timestamp: "2024-11-20 09:15 AM" },
        { id: 3, message: "5 documents uploaded successfully.", isRead: true, timestamp: "2024-11-19 03:45 PM" },
        { id: 4, message: "A document was flagged for review.", isRead: true, timestamp: "2024-11-18 01:20 PM" },
    ];

    // Separate notifications into read and unread categories
    const [notifications, setNotifications] = useState(mockNotifications);

    const markAsRead = (id) => {
        const updatedNotifications = notifications.map((notification) =>
            notification.id === id ? { ...notification, isRead: true } : notification
        );
        setNotifications(updatedNotifications);
    };

    const unreadNotifications = notifications.filter((n) => !n.isRead);
    const readNotifications = notifications.filter((n) => n.isRead);

    return (
        <div className="notifications-page">
            {/* Unread Notifications Section */}
            <section className="notifications-section unread">
                <h2>Unread Notifications</h2>
                {unreadNotifications.length > 0 ? (
                    <ul>
                        {unreadNotifications.map((notification) => (
                            <li key={notification.id} className="notification-item unread">
                                <div className="notification-message">{notification.message}</div>
                                <div className="notification-timestamp">{notification.timestamp}</div>
                                <button onClick={() => markAsRead(notification.id)}>Mark as Read</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="empty-state">No unread notifications.</p>
                )}
            </section>

            {/* Read Notifications Section */}
            <section className="notifications-section read">
                <h2>Read Notifications</h2>
                {readNotifications.length > 0 ? (
                    <ul>
                        {readNotifications.map((notification) => (
                            <li key={notification.id} className="notification-item read">
                                <div className="notification-message">{notification.message}</div>
                                <div className="notification-timestamp">{notification.timestamp}</div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="empty-state">No read notifications.</p>
                )}
            </section>
        </div>
    );
};

export default NotificationsPage;
