import React from "react";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/Card";

const StorageVisualization = ({ stats }) => {
    const storagePercentage = (stats.used / stats.limit) * 100;
    return (
        <Card>
            <CardHeader>
                <CardTitle>Storage Overview</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    <div className="flex justify-between text-sm text-gray-600">
                        <span>0GB used</span>
                        <span>15GB total</span>
                    </div>
                    <div className="w-full h-4 bg-gray-200 rounded-full relative">
                        <div
                            className="h-full bg-blue-500"
                            style={{width: "40%"}}
                            title="Documents: 40%"
                        ></div>
                        <div
                            className="h-full bg-yellow-500"
                            style={{width: "30%"}}
                            title="Photos: 30%"
                        ></div>
                        <div
                            className="h-full bg-red-500"
                            style={{width: "20%"}}
                            title="Videos: 20%"
                        ></div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default StorageVisualization;
