const BASE_URL = 'http://localhost:5001';
export const subscribeLead = async (formData) => {
    try {
        const response = await fetch(`${BASE_URL}/api/leads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Something went wrong.');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in subscribe service:', error.message);
        throw error;
    }
};
