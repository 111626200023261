import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "../services/contentfulClient";
import Navbar from "../components/Navbar";
import "./BlogPage.css";

const BlogPage = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "blogPost",
                    order: "-fields.date",
                });

                // Create a mapping of asset IDs to their URLs
                const assets = response.includes?.Asset || [];
                const assetMap = assets.reduce((map, asset) => {
                    map[asset.sys.id] = asset.fields.file.url; // Map asset ID to its file URL
                    return map;
                }, {});

                // Attach the resolved image URL to each post
                const postsWithImages = response.items.map((post) => {
                    const featuredImageId = post.fields.featuredImage?.sys?.id;
                    const imageUrl = featuredImageId ? `https:${assetMap[featuredImageId]}` : null;
                    return {
                        ...post,
                        fields: {
                            ...post.fields,
                            imageUrl, // Add resolved image URL
                        },
                    };
                });

                setPosts(postsWithImages);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching blog posts:", error);
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    if (loading) {
        return (
            <>
                <Navbar />
                <p>Loading blog posts...</p>
            </>
        );
    }

    return (
        <>
            <Navbar />
            <div className="blog-page">
                <h1 className="blog-title">Our Blog</h1>
                <div className="blog-grid">
                    {posts.map((post) => {
                        const { title, slug, content, imageUrl } = post.fields;

                        return (
                            <div key={slug} className="blog-card">
                                <img
                                    src={imageUrl || "https://via.placeholder.com/300x200"}
                                    alt={title || "Blog post"}
                                    className="blog-card-image"
                                />
                                <div className="blog-card-content">
                                    <h2 className="blog-card-title">{title}</h2>
                                    <p className="blog-card-description">
                                        {content?.content[0]?.content[0]?.value.substring(0, 100) ||
                                            "No description available."}
                                        ...
                                    </p>
                                    <Link to={`/blog/${slug}`} className="blog-card-link">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default BlogPage;
