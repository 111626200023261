import React from "react";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/Card";
import {UploadCloud} from "lucide-react";

const UploadWidget = () => {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Upload Documents</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="upload-section p-6 bg-white shadow rounded-lg">
                    <p className="text-gray-500 mb-4">Drag & drop your files here, or click to upload.</p>
                    <div
                        className="border-2 border-dashed border-gray-300 rounded-lg h-32 flex items-center justify-center"
                        onDrop={(e) => console.log("Files dropped", e)}
                    >
                        Drop your files here
                    </div>
                    <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg">
                        Upload
                    </button>
                </div>
            </CardContent>
        </Card>
    );
};

export default UploadWidget;
