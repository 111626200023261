import axios from "axios";

const API_URL = "http://localhost:5001/api/audit-logs"; // Adjust this if your API endpoint is different

// Helper function to get authentication headers
const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem('token')}`, // Retrieve token from localStorage
});

// Fetch audit logs with optional filters
const getAuditLogs = async (filters = {}) => {
    try {
        // Construct query parameters for filters
        const params = new URLSearchParams();

        Object.entries(filters).forEach(([key, value]) => {
            if (value) params.append(key, value);
        });

        const response = await axios.get(`${API_URL}?${params.toString()}`, {
            headers: getAuthHeaders(),
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching audit logs:", error.response?.data || error.message);
        throw error;
    }
};

export default {
    getAuditLogs,
};
