import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import "./UploadPage.css";

const UploadPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadMethod, setUploadMethod] = useState("manual"); // "manual" or "scanner"

  // Handle file selection via drag-and-drop with validation
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      alert("Some files were rejected. Please upload valid PDFs or images.");
    }

    // Log the accepted files
    console.log("Accepted Files:", acceptedFiles);

    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "image/jpeg": [],
      "image/png": [],
    },
    maxSize: 5 * 1024 * 1024, // 5 MB
  });

  const handleUpload = async () => {
    setUploading(true);

    const token = localStorage.getItem("token");

    if (uploadMethod === "manual") {
      const formData = new FormData(); // Ensure FormData is declared here
      selectedFiles.forEach((file) => {
        formData.append("file", file); // Use "file" as the key to match the backend
      });
      formData.append("title", "Uploaded Document"); // Add a title for the document

      console.log("Uploading with FormData:", [...formData.entries()]); // Debugging: Log the form data contents

      try {
        const response = await fetch("http://localhost:5001/api/documents/upload", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token for authentication
          },
          body: formData,
        });

        if (!response.ok) {
          const errorText = await response.text(); // Retrieve detailed error message
          throw new Error(errorText);
        }

        alert("Files uploaded successfully!");
      } catch (error) {
        console.error("Error uploading files:", error);
        alert(`Error: ${error.message}`);
      }
    } else if (uploadMethod === "scanner") {
      try {
        const response = await fetch("http://localhost:5001/api/scan", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token for authentication
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText);
        }

        alert("Documents scanned and uploaded successfully!");
      } catch (error) {
        console.error("Error scanning documents:", error);
        alert(`Error: ${error.message}`);
      }
    }

    setUploading(false);
    setSelectedFiles([]); // Clear the selected files after upload
  };


  return (
      <div className="upload-page">
        <h1>Upload Documents</h1>

        {/* Upload Method Selection */}
        <div className="upload-method">
          <label>
            <input
                type="radio"
                value="manual"
                checked={uploadMethod === "manual"}
                onChange={() => setUploadMethod("manual")}
            />
            Manual Upload
          </label>
          <label>
            <input
                type="radio"
                value="scanner"
                checked={uploadMethod === "scanner"}
                onChange={() => setUploadMethod("scanner")}
            />
            Use Scanner
          </label>
        </div>

        {/* Drag-and-Drop Area */}
        {uploadMethod === "manual" && (
            <div
                className={`drag-drop-area ${isDragActive ? "active" : ""}`}
                {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                  <p>Drop the files here...</p>
              ) : (
                  <p>Drag & drop files here, or click to select files</p>
              )}
            </div>
        )}

        {/* Selected Files Preview */}
        {selectedFiles.length > 0 && (
            <div className="file-preview">
              <h3>Selected Files:</h3>
              <ul>
                {selectedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
        )}

        <button
            onClick={handleUpload}
            disabled={uploading || (uploadMethod === "manual" && selectedFiles.length === 0)}
        >
          {uploading
              ? "Uploading..."
              : uploadMethod === "manual"
                  ? "Upload"
                  : "Scan & Upload"}
        </button>
      </div>
  );
};

export default UploadPage;
