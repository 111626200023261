import React, { useEffect, useRef } from "react";
import Navbar from "../components/Navbar"; // Import the Navbar component
import "./Home.css";
import HeroSection from "../components/Home/HeroSection";
import FeaturesSection from "../components/Home/FeaturesSection";
import HowItWorksSection from "../components/Home/HowItWorksSection";
import PricingSection from "../components/Home/PricingSection";
import LeadCaptureSection from "../components/Home/LeadCaptureSection";
import SideBySideCards from "../components/ui/SideBySideCards";


const Home = () => {
    const sectionRefs = {
        hero: useRef(null),
        features: useRef(null),
        howItWorks: useRef(null),
        pricing: useRef(null),
        savings: useRef(null), // Added savings reference
        contact: useRef(null),
    };

    // Function to scroll to a specific section
    const scrollToSection = (elementRef) => {
        elementRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    // IntersectionObserver to add animations
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    } else {
                        entry.target.classList.remove("visible");
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the section is visible
            }
        );

        const sections = document.querySelectorAll(".home > section");
        sections.forEach((section) => observer.observe(section));

        return () => {
            sections.forEach((section) => observer.unobserve(section));
        };
    }, []);

    return (
        <div className="home">
            <Navbar scrollToSection={scrollToSection} sectionRefs={sectionRefs} />
            <section ref={sectionRefs.hero} id="home">
                <HeroSection />
            </section>
            <section ref={sectionRefs.features} id="features">
                <FeaturesSection />
            </section>
            <section ref={sectionRefs.howItWorks} id="how-it-works">
                <HowItWorksSection />
            </section>
            <section ref={sectionRefs.pricing} id="pricing">
                <PricingSection />
            </section>
            {/* Savings Calculator and Why Go Digital */}
            <section ref={sectionRefs.savings} id="savings" className="savings-section">
                <SideBySideCards />
            </section>
            <section ref={sectionRefs.contact} id="lead-capture">
                <LeadCaptureSection />
            </section>
        </div>
    );
};

export default Home;
