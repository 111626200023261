import React from "react";
import { Folder, MoreVertical } from "lucide-react";

const RecentlyOpenedFolders = ({ folders }) => {
    return (
        <div className="recently-opened-folders p-4 bg-white shadow rounded-lg">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-bold">Recently Opened Folders</h3>
                <button className="text-gray-500 hover:text-black">
                    <MoreVertical />
                </button>
            </div>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                {folders.length > 0 ? (
                    folders.map((folder, index) => (
                        <div
                            key={index}
                            className="folder-card p-4 border rounded-lg shadow hover:shadow-lg transition"
                        >
                            <div className="flex items-center gap-3">
                                <Folder className="w-6 h-6 text-blue-500" />
                                <div>
                                    <h4 className="font-medium">{folder.name}</h4>
                                    <p className="text-sm text-gray-500">
                                        Last modified: {folder.lastModified}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-500">No recently opened folders.</p>
                )}
            </div>
        </div>
    );
};

export default RecentlyOpenedFolders;
