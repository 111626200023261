import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Ensure AuthContext is imported
import authService from '../services/authService'; // Adjust as needed
import './RegisterPage.css';

const RegisterPage = () => {
    const { login } = useContext(AuthContext); // Use login function from AuthContext
    const [formData, setFormData] = useState({
        businessName: '',
        contactPerson: '',
        email: '',
        phone: '',
        password: '',
        businessType: '',
        numOfEmployees: '',
        industry: '',
        documentsToDigitize: '',
        preferredContactMethod: '',
        termsAgreed: false,
    });

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setError(''); // Reset error message before making API call

        console.log("Form data being submitted:", formData); // Debugging: Log form data for review

        try {
            // Call the authService's register method with formData
            const response = await authService.register(formData);
            console.log("Registration successful:", response);

            // Automatically log in after successful registration
            const loginResponse = await authService.login(formData.email, formData.password);
            console.log("Login response:", loginResponse);

            if (loginResponse && loginResponse.token) {
                localStorage.setItem("token", loginResponse.token); // Store the token
                login(loginResponse.user); // Update AuthContext with the user
                navigate('/dashboard'); // Redirect to the dashboard
            } else {
                setError("Failed to log in after registration. Please try logging in manually.");
            }
        } catch (err) {
            // Check if the error message relates to an existing admin
            if (err.message && err.message.includes("admin")) {
                setError("This business already has an admin. Please contact your admin for access.");
            } else if (err.response && err.response.data && err.response.data.message) {
                // Handle specific backend error messages
                setError(err.response.data.message);
            } else {
                // Default fallback error message
                setError("Registration failed. Please try again.");
            }
            console.error("Registration error:", err); // Debugging: Log the error
        }
    };

    return (
        <div className="register-page">
            <div className="left-section">
                <h2>Simplify, Digitize, Thrive.</h2>
            </div>
            <div className="right-section">
                <form onSubmit={handleSubmit}>
                    <h2>Create an Account</h2>
                    {error && <p className="error-message">{error}</p>}
                    <input
                        type="text"
                        name="businessName"
                        placeholder="Business Name"
                        value={formData.businessName}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        name="contactPerson"
                        placeholder="Contact Person Name"
                        value={formData.contactPerson}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                    <select
                        name="businessType"
                        value={formData.businessType}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Business Type</option>
                        <option value="LLC">LLC</option>
                        <option value="Corporation">Corporation</option>
                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                    </select>
                    <input
                        type="number"
                        name="numOfEmployees"
                        placeholder="Number of Employees"
                        value={formData.numOfEmployees}
                        onChange={handleChange}
                        required
                    />
                    <select
                        name="industry"
                        value={formData.industry}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Industry</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Finance">Finance</option>
                        <option value="Retail">Retail</option>
                    </select>
                    <textarea
                        name="documentsToDigitize"
                        placeholder="Types of Documents to Digitize"
                        value={formData.documentsToDigitize}
                        onChange={handleChange}
                    />
                    <select
                        name="preferredContactMethod"
                        value={formData.preferredContactMethod}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Preferred Contact Method</option>
                        <option value="Email">Email</option>
                        <option value="Phone">Phone</option>
                    </select>
                    <label>
                        <input
                            type="checkbox"
                            name="termsAgreed"
                            checked={formData.termsAgreed}
                            onChange={handleChange}
                            required
                        />
                        I agree to the Terms & Conditions
                    </label>
                    <button type="submit" disabled={loading}>
                        {loading ? "Registering..." : "Register"}
                    </button>
                    <div className="link">
                        <p>Already have an account? <a href="/login">Log in</a></p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;
