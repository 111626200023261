import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import './Sidebar.css';
import logo from "../assets/paperlessiq-logo.png"; // Adjust path if needed
import {
    FiHome,
    FiUpload,
    FiSearch,
    FiBarChart2,
    FiFileText,
    FiAlertCircle,
    FiSettings,
    FiBell,
    FiTag,
    FiUsers,
    FiLayers,
} from "react-icons/fi";
import { AuthContext } from "../context/AuthContext"; // Assuming AuthContext is used

const Sidebar = () => {
    const { user } = useContext(AuthContext); // Get the user from context
    const isAdmin = user?.role === "admin"; // Check if the user is an admin

    return (
        <div className="sidebar">
            <div className="logo-container">
                <img src={logo} alt="PaperlessIQ Logo" className="logo" />
            </div>
            <h2>Menu</h2>
            <ul>
                {/* General Links */}
                <li>
                    <NavLink to="/dashboard" activeClassName="active">
                        <FiHome /> Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/upload" activeClassName="active">
                        <FiUpload /> Upload & Scan
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/search" activeClassName="active">
                        <FiSearch /> Search
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/analytics" activeClassName="active">
                        <FiBarChart2 /> Analytics Reports
                    </NavLink>
                </li>

                {/* Admin-Only Links */}
                {isAdmin && (
                    <>
                        <li>
                            <NavLink to="/audit-logs" activeClassName="active">
                                <FiFileText /> Audit Logs
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/compliance-alerts" activeClassName="active">
                                <FiAlertCircle /> Compliance Alerts
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/export" activeClassName="active">
                                <FiLayers /> Export
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/integration-settings" activeClassName="active">
                                <FiSettings /> Integration Settings
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/notifications" activeClassName="active">
                                <FiBell /> Notifications
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/tag-management" activeClassName="active">
                                <FiTag /> Tag Management
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user-management" activeClassName="active">
                                <FiUsers /> User Management
                            </NavLink>
                        </li>
                    </>
                )}

                {/* Settings (Accessible to All) */}
                <li>
                    <NavLink to="/settings" activeClassName="active">
                        <FiSettings /> Settings
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
