import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import searchService from "../services/searchService"; // Ensure your service file is correctly imported
import "./SearchPage.css";

const SearchPage = () => {
  const [filters, setFilters] = useState({
    query: "",
    tags: "",
    startDate: "",
    endDate: "",
    fileType: "",
    status: "",
  });
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // Handle search action
  const handleSearch = async () => {
    setLoading(true);
    setError("");
    try {
      const results = await searchService.searchDocuments(filters);
      setDocuments(results);
    } catch (err) {
      setError(err.message || "Failed to fetch search results");
    } finally {
      setLoading(false);
    }
  };

  // Handle reset filters
  const handleReset = () => {
    setFilters({
      query: "",
      tags: "",
      startDate: "",
      endDate: "",
      fileType: "",
      status: "",
    });
    setDocuments([]);
  };

  return (
      <div className="search-page">
        <header className="search-header">
          <h1>Document Search</h1>
          <div className="stats">
            <div className="stat-item">
              <h3>Items</h3>
              <p>{documents.length}</p>
            </div>
            <div className="stat-item">
              <h3>Tags</h3>
              <p>{filters.tags ? filters.tags.split(",").length : 0}</p>
            </div>
            <div className="stat-item">
              <h3>Status</h3>
              <p>{filters.status || "All"}</p>
            </div>
          </div>
        </header>

        <div className="filters-section">
          <div className="filter-group">
            <label>Search Query</label>
            <input
                type="text"
                name="query"
                placeholder="Enter keywords"
                value={filters.query}
                onChange={handleInputChange}
            />
          </div>

          <div className="filter-group">
            <label>Tags</label>
            <input
                type="text"
                name="tags"
                placeholder="Comma-separated tags"
                value={filters.tags}
                onChange={handleInputChange}
            />
          </div>

          <div className="filter-group">
            <label>Start Date</label>
            <input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleInputChange}
            />
          </div>

          <div className="filter-group">
            <label>End Date</label>
            <input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleInputChange}
            />
          </div>

          <div className="filter-group">
            <label>File Type</label>
            <select name="fileType" value={filters.fileType} onChange={handleInputChange}>
              <option value="">All</option>
              <option value="application/pdf">PDF</option>
              <option value="image/jpeg">JPEG</option>
              <option value="image/png">PNG</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Status</label>
            <select name="status" value={filters.status} onChange={handleInputChange}>
              <option value="">All</option>
              <option value="Uploaded">Uploaded</option>
              <option value="Processed">Processed</option>
              <option value="Flagged">Flagged</option>
              <option value="Completed">Completed</option>
            </select>
          </div>

          <div className="filter-buttons">
            <button onClick={handleSearch} disabled={loading}>
              {loading ? "Searching..." : "Search"}
            </button>
            <button onClick={handleReset}>Reset</button>
          </div>
        </div>

        <div className="results-section">
          {error && <p className="error-message">{error}</p>}

          {documents.length > 0 ? (
              <table className="results-table">
                <thead>
                <tr>
                  <th>Title</th>
                  <th>Tags</th>
                  <th>Uploaded</th>
                  <th>Status</th>
                  <th>File Type</th>
                </tr>
                </thead>
                <tbody>
                {documents.map((doc) => (
                    <tr key={doc._id}>
                      <td>
                        <Link to={`/document/${doc._id}`}>{doc.title}</Link>
                      </td>
                      <td>{doc.tags.join(", ") || "None"}</td>
                      <td>{new Date(doc.uploadDate).toLocaleDateString()}</td>
                      <td>{doc.status}</td>
                      <td>{doc.metadata.fileType}</td>
                    </tr>
                ))}
                </tbody>
              </table>
          ) : (
              <p>No documents found. Adjust your search criteria and try again.</p>
          )}
        </div>
      </div>
  );
};

export default SearchPage;
