import React, { useState, useEffect } from "react";
import auditLogsService from "../services/auditLogsService";
import "./AuditLogsPage.css";

const AuditLogsPage = () => {
    const [logs, setLogs] = useState([]);
    const [filters, setFilters] = useState({
        startDate: "",
        endDate: "",
        user: "",
        actionType: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const fetchLogs = async () => {
        setLoading(true);
        setError("");
        try {
            const data = await auditLogsService.getAuditLogs(filters);
            setLogs(data);
        } catch (err) {
            setError(err.message || "Failed to fetch audit logs.");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleSearch = () => {
        fetchLogs();
    };

    const handleReset = () => {
        setFilters({
            startDate: "",
            endDate: "",
            user: "",
            actionType: "",
        });
        setLogs([]);
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    return (
        <div className="audit-logs-page">
            <h1>Audit Logs</h1>

            {/* Filters Section */}
            <div className="filters-section">
                <div className="filter-group">
                    <label>Start Date</label>
                    <input
                        type="date"
                        name="startDate"
                        value={filters.startDate}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="filter-group">
                    <label>End Date</label>
                    <input
                        type="date"
                        name="endDate"
                        value={filters.endDate}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="filter-group">
                    <label>User</label>
                    <input
                        type="text"
                        name="user"
                        placeholder="Enter user ID or name"
                        value={filters.user}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="filter-group">
                    <label>Action Type</label>
                    <select
                        name="actionType"
                        value={filters.actionType}
                        onChange={handleInputChange}
                    >
                        <option value="">All</option>
                        <option value="upload">Upload</option>
                        <option value="edit">Edit</option>
                        <option value="delete">Delete</option>
                        <option value="login">Login</option>
                        <option value="logout">Logout</option>
                    </select>
                </div>
                <div className="filter-buttons">
                    <button onClick={handleSearch} disabled={loading}>
                        {loading ? "Loading..." : "Search"}
                    </button>
                    <button onClick={handleReset}>Reset</button>
                </div>
            </div>

            {/* Logs Table */}
            <div className="logs-section">
                {error && <p className="error-message">{error}</p>}
                {logs.length > 0 ? (
                    <table className="logs-table">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Action Type</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {logs.map((log, index) => (
                            <tr key={index}>
                                <td>{new Date(log.date).toLocaleString()}</td>
                                <td>{log.user}</td>
                                <td>{log.actionType}</td>
                                <td>{log.details}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No logs found. Try adjusting the filters.</p>
                )}
            </div>
        </div>
    );
};

export default AuditLogsPage;
