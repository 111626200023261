import React from "react";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/Card";

const RecentUploads = ({ uploads }) => {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Recently Uploaded by Team</CardTitle>
            </CardHeader>
            <CardContent>
                {uploads.length > 0 ? (
                    <ul className="space-y-4">
                        {uploads.length > 0 ? (
                            uploads.map((file, index) => (
                                <li
                                    key={index}
                                    className="flex items-center justify-between p-4 bg-white shadow rounded-lg"
                                >
                                    <div>
                                        <h4 className="font-bold">{file.title || "Untitled Document"}</h4>
                                        <p className="text-sm text-gray-500">
                                            Uploaded by {file.uploaderName} on {file.uploadDate}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <button className="text-blue-500">View</button>
                                        <button className="text-blue-500">Download</button>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <p className="text-gray-500">No recent uploads found.</p>
                        )}
                    </ul>
                ) : (
                    <p>No recent uploads found.</p>
                )}
            </CardContent>
        </Card>
    );
};

export default RecentUploads;
