import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/paperlessiq-logo.png"; // Adjust the path if needed
import "./Navbar.css"; // Create a separate CSS file for navbar styles

const Navbar = ({ scrollToSection, sectionRefs }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    // Toggle for showing Sign In and Sign Up links
    const showAuthLinks = false; // Set to true for development, false for production

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <header className="navbar-header">
            {/* Wrap the logo in a div with an onClick handler */}
            <div className="navbar-logo" onClick={() => navigate("/")}>
                <img src={logo} alt="PaperlessIQ Logo" className="logo-image" />
            </div>
            <nav className={`navbar-nav ${isMenuOpen ? "nav-open" : ""}`}>
                <ul className="nav-links">
                    <li>
                        {scrollToSection && sectionRefs ? (
                            <a onClick={() => scrollToSection(sectionRefs.hero)}>Home</a>
                        ) : (
                            <a onClick={() => navigate("/")}>Home</a>
                        )}
                    </li>
                    <li>
                        <a onClick={() => navigate("/about")}>About</a>
                    </li>
                    <li>
                        {scrollToSection && sectionRefs && (
                            <a onClick={() => scrollToSection(sectionRefs.features)}>
                                Features
                            </a>
                        )}
                    </li>
                    <li>
                        {scrollToSection && sectionRefs && (
                            <a onClick={() => scrollToSection(sectionRefs.pricing)}>
                                Pricing
                            </a>
                        )}
                    </li>
                    <li>
                        {scrollToSection && sectionRefs && (
                            <a onClick={() => scrollToSection(sectionRefs.contact)}>
                                Contact Us
                            </a>
                        )}
                    </li>
                    <li>
                        <a onClick={() => navigate("/blog")}>Blog</a>
                    </li>
                </ul>
                {/* Conditionally render Sign In and Sign Up links */}
                {showAuthLinks && (
                    <div className="nav-actions">
                        <button
                            className="login-button"
                            onClick={() => navigate("/login")}
                        >
                            Sign In
                        </button>
                        <button
                            className="signup-button"
                            onClick={() => navigate("/register")}
                        >
                            Sign Up
                        </button>
                    </div>
                )}
            </nav>
            <button className="hamburger" onClick={toggleMenu}>
                {isMenuOpen ? "✖" : "☰"}
            </button>
        </header>
    );
};

export default Navbar;
