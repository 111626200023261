import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import UploadPage from "./pages/UploadPage";
import DocumentDetailsPage from "./pages/DocumentDetailsPage";
import SearchPage from "./pages/SearchPage";
import Home from "./pages/Home";
import AnalyticsReportsPage from "./pages/AnalyticsReportsPage";
import AuditLogsPage from "./pages/AuditLogsPage";
import ComplianceAlertPage from "./pages/ComplianceAlertPage";
import SettingsPage from "./pages/SettingsPage";
import ExportPage from "./pages/ExportPage";
import NotificationsPage from "./pages/NotificationsPage";
import IntegrationSettingsPage from "./pages/IntegrationSettingsPage";
import UserManagementPage from "./pages/UserManagementPage";
import TagManagementPage from "./pages/TagManagementPage";
import Footer from "./components/Footer";
import AuthenticatedLayout from "./components/AuthenticatedLayout";
import ProtectedRoute from "./components/ProtectedRoute";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ContactUs from "./pages/ContactUs";
import AboutPage from "./pages/AboutPage";
import "./styles/tailwind.css";
import BlogPage from "./pages/BlogPage";
import BlogPost from "./pages/BlogPost";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage
      if (token) {
        try {
          const response = await fetch("http://localhost:5001/api/auth/validate", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setIsAuthenticated(response.ok); // Set true if token is valid
        } catch (error) {
          console.error("Error validating token:", error);
          setIsAuthenticated(false); // Invalid token
        }
      } else {
        setIsAuthenticated(false); // No token found
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === undefined) {
    return <div className="flex justify-center items-center h-screen">Loading authentication...</div>;
  }

  return (
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogPost />} />

            {/* Protected Routes with Layout */}
            <Route element={<AuthenticatedLayout />}>
              <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/upload" element={<UploadPage />} />
                <Route path="/documents/:documentId" element={<DocumentDetailsPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/analytics" element={<AnalyticsReportsPage />} />
                <Route path="/audit-logs" element={<AuditLogsPage />} />
                <Route path="/compliance-alerts" element={<ComplianceAlertPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/export" element={<ExportPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/integration-settings" element={<IntegrationSettingsPage />} />
                <Route path="/user-management" element={<UserManagementPage />} />
                <Route path="/tag-management" element={<TagManagementPage />} />
                <Route path="/document/:documentId" element={<DocumentDetailsPage />} />
              </Route>
            </Route>
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>
  );
};

export default App;
