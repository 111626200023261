import React from "react";
import "./HeroSection.css"; // Separate CSS for the Hero Section

const HeroSection = () => {
    const handleBookConsultation = () => {
        window.open(
            "https://calendly.com/brittain-laurin/paperlessiq-consultation-call",
            "_blank"
        );
    };

    return (
        <section className="hero">
            <div className="hero-overlay"></div> {/* For background effects */}
            <div className="hero-content">
                <h1 className="hero-title">
                    Document Digitization Services in Houston, TX
                </h1>
                <p className="hero-subtitle">
                    Make your business paperwork smarter with PaperlessIQ. We help Houston businesses transition from paper to digital, enabling secure, searchable, and AI-ready document management solutions.
                </p>
                <ul className="hero-benefits">
                    <li>✔ Transform your physical paperwork into secure digital formats.</li>
                    <li>✔ Save time with AI-powered organization and search capabilities.</li>
                    <li>✔ Free up office space and enhance your operational efficiency.</li>
                </ul>
                <button
                    className="consultation-button"
                    onClick={handleBookConsultation}
                >
                    Book a Free Consultation
                </button>
                <p className="hero-disclaimer">
                    * We proudly serve businesses in Houston, Sugar Land, The Woodlands, and surrounding areas.
                </p>
            </div>
        </section>
    );
};

export default HeroSection;
