import React, { useEffect, useState } from "react";
import complianceService from "../services/complianceService"; // Service to interact with the backend
import "./ComplianceAlertPage.css";

const ComplianceAlertPage = () => {
    const [alerts, setAlerts] = useState([]);
    const [filters, setFilters] = useState({
        issueType: "",
        startDate: "",
        endDate: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchAlerts();
    }, []);

    const fetchAlerts = async () => {
        setLoading(true);
        setError("");
        try {
            const data = await complianceService.getComplianceAlerts(filters);
            setAlerts(data);
        } catch (err) {
            setError(err.message || "Failed to fetch compliance alerts.");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleFilterApply = () => {
        fetchAlerts();
    };

    return (
        <div className="compliance-alert-page">
            <h1>Compliance Alerts</h1>

            <div className="filters-section">
                <div className="filter-group">
                    <label>Issue Type</label>
                    <select
                        name="issueType"
                        value={filters.issueType}
                        onChange={handleInputChange}
                    >
                        <option value="">All</option>
                        <option value="missingFields">Missing Fields</option>
                        <option value="incompleteDocuments">Incomplete Documents</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="filter-group">
                    <label>Start Date</label>
                    <input
                        type="date"
                        name="startDate"
                        value={filters.startDate}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="filter-group">
                    <label>End Date</label>
                    <input
                        type="date"
                        name="endDate"
                        value={filters.endDate}
                        onChange={handleInputChange}
                    />
                </div>
                <button onClick={handleFilterApply} disabled={loading}>
                    {loading ? "Applying Filters..." : "Apply Filters"}
                </button>
            </div>

            {error && <p className="error-message">{error}</p>}

            <div className="alerts-section">
                {alerts.length > 0 ? (
                    <table className="alerts-table">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Issue</th>
                            <th>Date Flagged</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {alerts.map((alert) => (
                            <tr key={alert._id}>
                                <td>{alert.title}</td>
                                <td>{alert.issueType}</td>
                                <td>{new Date(alert.dateFlagged).toLocaleDateString()}</td>
                                <td>
                                    <button
                                        onClick={() =>
                                            console.log("Navigating to document:", alert._id)
                                        }
                                    >
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No compliance alerts found. Adjust your filters and try again.</p>
                )}
            </div>
        </div>
    );
};

export default ComplianceAlertPage;
