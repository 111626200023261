const API_URL = "http://localhost:5001/api/search"; // Base path for search APIs

const searchDocuments = async (filters = {}) => {
    const token = localStorage.getItem('token');
    console.log("Retrieved token:", token);

    if (!token) {
        throw new Error("Authentication token is missing.");
    }

    // Construct URL with filters as query parameters
    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
        if (value) {
            params.append(key, value);
        }
    });

    try {
        const response = await fetch(`${API_URL}?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error("Error from API:", errorText);
            throw new Error(errorText);
        }

        const data = await response.json();
        console.log("Search results from API:", data);
        return data;
    } catch (error) {
        console.error("Search request failed:", error);
        throw error;
    }
};

const getFilters = async () => {
    const token = localStorage.getItem('token');
    console.log("Retrieved token:", token);

    if (!token) {
        throw new Error("Authentication token is missing.");
    }

    try {
        const response = await fetch(`${API_URL}/filters`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error("Error from API:", errorText);
            throw new Error(errorText);
        }

        const data = await response.json();
        console.log("Filters from API:", data);
        return data;
    } catch (error) {
        console.error("Fetching filters failed:", error);
        throw error;
    }
};

export default {
    searchDocuments,
    getFilters,
};
