import React, { useState } from "react";
import { subscribeLead } from "../../services/subscribeService";
import "./LeadCaptureSection.css";

const LeadCaptureSection = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        company: "",
        message: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState({ type: "", message: "" });

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus({ type: "", message: "" });

        if (!validateEmail(formData.email)) {
            setStatus({
                type: "error",
                message: "Please enter a valid email address.",
            });
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await subscribeLead(formData);
            setStatus({
                type: "success",
                message:
                    response.message ||
                    "Thank you for reaching out! We’ll contact you soon.",
            });
            setFormData({
                name: "",
                email: "",
                phone: "",
                company: "",
                message: "",
            });
        } catch (error) {
            console.error("Error submitting form:", error);
            setStatus({
                type: "error",
                message:
                    error.response?.data?.message ||
                    "An error occurred. Please try again later.",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="lead-capture-section">
            <div className="lead-capture-container">
                <div className="lead-capture-text">
                    <h2>Ready to Get Started?</h2>
                    <p>
                        Let us help you simplify your workflow. Share your details, and
                        our team will be in touch shortly to discuss how we can best serve
                        your needs.
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="lead-capture-form">
                    <div className="form-group">
                        <label htmlFor="name">Your Name</label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            required
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Your Email</label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Your Phone</label>
                        <input
                            id="phone"
                            type="text"
                            name="phone"
                            placeholder="Your Phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="company">Your Company</label>
                        <input
                            id="company"
                            type="text"
                            name="company"
                            placeholder="Your Company"
                            value={formData.company}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">How can we help you?</label>
                        <textarea
                            id="message"
                            name="message"
                            placeholder="How can we help you?"
                            rows="4"
                            required
                            value={formData.message}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Submit Information"}
                    </button>
                </form>
                {status.message && (
                    <div className={`form-status ${status.type}`}>
                        {status.message}
                    </div>
                )}
            </div>
        </section>
    );
};

export default LeadCaptureSection;
