import React from "react";
import NotificationsCenter from "../components/Dashboard/NotificationsCenter";
import StorageVisualization from "../components/Dashboard/StorageVisualization";
import UploadWidget from "../components/Dashboard/UploadWidget";
import RecentUploads from "../components/Dashboard/RecentUploads";
import RecentlyOpenedFolders from "../components/Dashboard/RecentlyOpenedFolders";

const DashboardPage = () => {
  // Mock data for notifications
  const mockNotifications = [
    { message: "Your document 'Report Q3' was approved.", id: 1 },
    { message: "You have a new compliance alert.", id: 2 },
    { message: "5 documents uploaded successfully.", id: 3 },
  ];

  const mockRecentUploads = [
    { title: "Project Proposal", uploaderName: "John Doe", fileUrl: "#" },
    { title: "Annual Report", uploaderName: "Jane Smith", fileUrl: "#" },
    { title: "Budget Plan", uploaderName: "Emily Davis", fileUrl: "#" },
  ];

  const mockRecentFolders = [
    { name: "Marketing Plans", lastModified: "16 Nov" },
    { name: "Client Contracts", lastModified: "14 Nov" },
    { name: "Design Assets", lastModified: "12 Nov" },
  ];

  const mockStorageStats = {
    used: 8,
    limit: 15, // GB
    fileTypes: [
      { type: "Documents", percentage: 40, color: "bg-blue-500" },
      { type: "Photos", percentage: 30, color: "bg-yellow-500" },
      { type: "Videos", percentage: 20, color: "bg-red-500" },
    ],
  };

  return (
      <div className="dashboard-content">
        {/* Notifications Center */}
        <section className="notifications-section">
          <h3 className="section-title"></h3>
          <NotificationsCenter notifications={mockNotifications} />
        </section>

        {/* Storage Overview & Upload Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <StorageVisualization stats={mockStorageStats} />
          <UploadWidget />
        </div>

        {/* Recent Folders and Uploads */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <RecentlyOpenedFolders folders={mockRecentFolders} />
          <RecentUploads uploads={mockRecentUploads} />
        </div>
      </div>
  );
};

export default DashboardPage;
