import React from "react";
import "../styles/PageStyles.css";

const ContactUs = () => {
    return (
        <div className="page-container">
            <h1>Contact Us</h1>
            <p>If you have any questions, feel free to reach out:</p>
            <p>Email: <a href="mailto:support@paperlessiq.com">support@paperlessiq.com</a></p>
            <p>Phone: +1-234-567-8901</p>
            <p>Address: 123 Digital Lane, Suite 100, Tech City, TX 75001</p>
        </div>
    );
};

export default ContactUs;
