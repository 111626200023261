import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { AuthContext } from "../context/AuthContext";

const AuthenticatedLayout = () => {
    const { isAuthenticated } = useContext(AuthContext);

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return (
        <div style={{ display: "flex" }}>
            <Sidebar />
            <main style={{ flex: 1 }}>
                <Outlet />
            </main>
        </div>
    );
};

export default AuthenticatedLayout;
